import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/title';

const NotFoundPage = () => (
  <Layout centered mainClassName="bg-gray-50">
    <SEO title="404: Not found" />
    <section className="p-6 sm:py-12 bg-gray-50 text-center">
      <div className="container mx-auto">
        <Title alternate>404: NOT FOUND</Title>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
